import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography } from '../../../Typography/Typography';
import { BlogHeroProps } from '../../types/blogs/blogHero';
import { BlogHeroContent } from './BlogHeroContent';
import { format } from 'date-fns';
import { validateBlogUrl, getBlogData } from '../../../builder/utils/blog';
import { handleCategoryButtonClick } from './BlogPostHero';
import { LoadingContainer } from '../../../../components/loading-container/LoadingContainer';

const ROTATE_INTERVAL = 8000;

export function BlogHeroCarousel({ builderState }: BlogHeroProps) {
    const [currentSlide, setCurrentSlide] = useState(0);
    const rotateInterval = useRef<NodeJS.Timeout | null>(null);
    const blogs = [
        getBlogData('blogs', builderState),
        getBlogData('blogs2', builderState),
        getBlogData('blogs3', builderState),
    ];
    const rotate = useCallback(() => {
        setCurrentSlide((current) => (current + 1) % blogs.length);
    }, [blogs.length]);

    useEffect(() => {
        rotateInterval.current = setInterval(rotate, ROTATE_INTERVAL);

        return () => {
            if (rotateInterval.current) {
                clearInterval(rotateInterval.current);
            }
        };
    }, [rotate, currentSlide]);

    const currentBlog = blogs[currentSlide];
    const formattedDate = currentBlog.publishedDate && format(new Date(currentBlog.publishedDate), 'MMM dd yyyy');

    return (
        <LoadingContainer isLoading={!currentBlog?.featuredImage} className="w-full">
            <section className="mx-auto md:container lg:px-4 md:px-4 md:pt-[50px] md:mb-[50px]">
                <div className="hidden md:block relative">
                    <div className="flex items-center">
                        {currentBlog?.fullName && currentBlog?.headline && (
                            <BlogHeroContent
                                publishDate={formattedDate}
                                minuteRead={currentBlog.minuteRead}
                                headline={currentBlog.headline}
                                categories={currentBlog.categories}
                                fullName={currentBlog.fullName}
                                avatarImage={currentBlog.avatarImage}
                                imageAltText={currentBlog.imageAltText}
                                blogUrl={currentBlog.blogUrl}
                                handleCategoryButtonClick={handleCategoryButtonClick}
                                onAuthorClick={() => {
                                    const url = currentBlog.authorUrl;

                                    if (typeof window !== 'undefined' && validateBlogUrl(url)) {
                                        window.location.href = url;
                                    }
                                }}
                                showCategories={false}
                            />
                        )}
                        {/* Feature Image container */}
                        <div className="flex-1 relative -ml-[268px] justify-center items-center md:-ml-[180px]">
                            <img
                                src={currentBlog.featuredImage}
                                alt={currentBlog.featuredImageAlt}
                                className="w-full h-full sm:min-h-[608.88px] sm:max-w-[638px] lg:max-w-none object-cover sm:object-[80%] lg:object-cover rounded-[25.865px] transition-opacity hover:opacity-70 cursor-pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    const url = currentBlog.blogUrl;

                                    if (typeof window !== 'undefined') {
                                        window.location.href = url;
                                    }
                                }}
                                role="button"
                                aria-label={`Read about ${currentBlog.headline}`}
                            />
                        </div>
                    </div>
                </div>

                {/* Mobile View - Similar to BlogPostHero mobile view */}
                <section className="md:hidden relative h-[536.85] overflow-hidden">
                    <div className="bg-milan-blue px-4 pt-[50px] pb-24 relative z-0 flex flex-col items-start justify-center gap-4">
                        <div className="flex items-center gap-2">
                            <Typography variant="h4Footer" color="milan-text-white" tag="span">
                                {formattedDate}
                            </Typography>
                            <Typography variant="h4Footer" color="milan-text-white" tag="span">
                                •
                            </Typography>
                            <Typography variant="h4Footer" color="milan-text-white" tag="span">
                                {currentBlog.minuteRead} MINUTE READ
                            </Typography>
                        </div>

                        <Typography
                            variant="h1SmallTitle"
                            color="milan-text-white"
                            tag="h1"
                            className="!pb-0 break-words overflow-wrap break-word"
                        >
                            {currentBlog.headline}
                        </Typography>

                        <div className="flex flex-row justify-start flex-wrap  md:w-full">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    const url = currentBlog.blogUrl;

                                    if (typeof window !== 'undefined') {
                                        window.location.href = url;
                                    }
                                }}
                                className="bg-milan-secondary-blue text-milan-navy px-[19.33px] py-[6.33px] rounded-[12.89px] font-medium flex items-center justify-center hover:bg-milan-white outline-none border-none font-open-sans"
                            >
                                <Typography
                                    variant="pDefault"
                                    color="milan-text-milan-navy"
                                    tag="span"
                                    className=" !m-0 !p-0"
                                >
                                    Read More
                                </Typography>
                            </button>
                        </div>
                    </div>

                    <div className="relative w-full min-h-[214px] mb-16  px-3 sm:pt-0 flex justify-center left-1/2 transform -translate-x-1/2 mt-[-60px] z-10">
                        <img
                            src={currentBlog.featuredImage}
                            alt={currentBlog.featuredImageAlt}
                            className="w-full h-auto object-cover rounded-[9.19px] cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                                const url = currentBlog.blogUrl;

                                if (typeof window !== 'undefined') {
                                    window.location.href = url;
                                }
                            }}
                            role="button"
                            aria-label={`Read about ${currentBlog.headline}`}
                        />
                    </div>
                </section>

                {/* Pagination Dots - visible on all screen sizes */}
                <div className="flex justify-center gap-[20px] md:gap-[28.667px]   md:mt-8 relative z-50">
                    {blogs.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                setCurrentSlide(index);

                                if (rotateInterval.current) {
                                    clearInterval(rotateInterval.current);
                                }

                                rotateInterval.current = setTimeout(() => {
                                    rotateInterval.current = setInterval(rotate, ROTATE_INTERVAL);
                                }, 2000);
                            }}
                            className={`w-[9.10px] h-[9.10px] sm:w-4 sm:h-4  rounded-full border-solid border-milan-blue  ${
                                currentSlide === index
                                    ? 'bg-milan-blue'
                                    : 'bg-milan-white  border-[1px]   sm:border-2 border-solid border-milan-blue hover:bg-milan-secondary-blue'
                            }`}
                            aria-label={`View blog ${index + 1}`}
                            aria-pressed={currentSlide === index}
                        />
                    ))}
                </div>
                <div />
            </section>
        </LoadingContainer>
    );
}
