// src/components/builder/blog/BlogPostHero.builder.tsx

import { Builder } from '@builder.io/react';
import { BUILDER_BLOG_COMPONENTS_NAMES } from '../BuilderComponentsName';
import { BlogPostHero } from '../components/blogs/BlogPostHero';
// @ts-ignore: This is a JavaScript component without TypeScript definitions
import { builderModels } from '../../../hooks/builderPage';

Builder.registerComponent(BlogPostHero, {
    name: BUILDER_BLOG_COMPONENTS_NAMES.BlogPostHero,
    friendlyName: 'Blog Post Hero',
    models: [builderModels.blogPage, builderModels.symbol] as const,
    image: 'https://cdn.builder.io/api/v1/image/assets%2F10266dac478d4c768f6b6fbe586ae30e%2Fd43dd9277195480dad336e9099bf6ef5',
});
