import React from 'react';
import { MilanLoader } from '../milan-loader/milan-loader';

export function LoadingContainer({ children, isLoading = false }) {
    if (isLoading) {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className="p-5">
                <MilanLoader />
            </div>
        );
    }

    return <>{children}</>;
}
